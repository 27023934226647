import React from 'react'
import './Footer.css';
function footer() {
  return (
    <footer>
	<div>
		<p class="text-center">Copyright 2025 | All Rights Reserved | <a href="/privacy-policy">Privacy Policy</a></p>
	</div>
</footer>
  )
}

export default footer